.roundedButton {
  border-radius: 50%;
  height: 400px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 8px solid rgb(66, 65, 69);
  font-size: 50px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  padding: 50px;
}

.infoText {
  font-size: 20px;
}

.hour {
  margin: 10px;
  font-size: 40px;
}
