.container {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  padding: 5px 5px 5px 5px;
}

.commandButton {
  margin: 2px 2px 2px 2px;
}
