.configContainer {
  background-color: rgba(255, 255, 255, 0.35);
  position: absolute;
  width: 90vw;
  padding: 5px;
}

.divider {
  margin: 5px;
}

.configForm {
  text-align: left;
  height: auto;
}

.labelTable {
  font-weight: bold;
}
