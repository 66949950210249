.favoriteContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 5vw;
  row-gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
