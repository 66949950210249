.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-image: url(images/background.jpg);
  background-size: cover;
  height: 100vh;
}

.header {
  height: 5vh;
}

.main {
  height: 85vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  flex-wrap: wrap;
  flex-grow: 1;
  padding: 5vw;
  row-gap: 20px;

  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 10vh;
}

.logo {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.rss {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.startBtn {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 8px solid rgb(66, 65, 69);
  font-size: 30px;
}

.backButton {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin: 5px 5px 5px 5px;
}

.status {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
  width: 32px;
  height: 32px;
}
